import React from "react";
import {css, Theme} from "@emotion/react";
import {isBefore, parseISO} from "date-fns";

import {Container} from "@pg-design/grid-module";
import {flex, flexAbsoluteCenter, mb, mt, onDesktop, ph} from "@pg-design/helpers-css";
import {AirportIcon, AreaIcon, BeachIcon, BookmarkIcon, CalendarCheckIcon, CityIcon, FloorsIcon, LikeIcon, RoomsIcon} from "@pg-design/icons-module";
import {dateTimeFormat, formatDate, formatRange} from "@pg-mono/string-utils";

import {Country} from "../../region/types/Country";
import {useOfferStandardDescription} from "../detail/hooks/use_offer_standard_description";
import {IOfferDetail} from "../types/IOfferDetail";
import {UVP} from "./UVP";

interface IProps {
    offer: IOfferDetail;
    standardType: IValueLabel[] | string;
}

interface IValueLabel {
    label: string;
    value: number;
}
export const OfferUVP = (props: IProps) => {
    const {offer} = props;

    const standard = useOfferStandardDescription(offer.standard_description);

    const area = formatRange([offer.stats?.ranges_area_min, offer.stats?.ranges_area_max]);
    const floors = formatRange([offer.floors_above_ground_range?.lower, offer.floors_above_ground_range?.upper]);
    const underConstruction = isBefore(new Date(), parseISO(offer.construction_date_range.upper));
    const constructionDate = formatDate(offer.construction_date_range.upper, dateTimeFormat.quarter);
    const abroadOffer = offer.region.country !== Country.POLAND;
    const distanceFromTheAirport = offer.distance_from_the_airport ? distanceFromFormat(offer.distance_from_the_airport) : null;
    const distanceFromTheBeach = offer.distance_from_the_beach ? distanceFromFormat(offer.distance_from_the_beach) : null;

    return (
        <Container css={[mt(3), mb(8), flexAbsoluteCenter, onDesktop(mt(6))]}>
            <div css={uvps}>
                {constructionDate && (
                    <UVP icon={<CalendarCheckIcon size="4" />} label="Termin oddania" value={underConstruction ? constructionDate : "Gotowe"} />
                )}
                {area && (
                    <UVP
                        icon={<AreaIcon size="4" />}
                        label="Powierzchnie"
                        value={
                            <>
                                {area}/m<sup>2</sup>
                            </>
                        }
                    />
                )}
                <UVP icon={<RoomsIcon size="4" />} label="Przedział pokoi" value={getRoomsRange(offer)} />
                {abroadOffer && (
                    <>
                        {distanceFromTheAirport && <UVP icon={<AirportIcon size="4" />} label="Odległość od lotniska" value={distanceFromTheAirport} />}
                        {distanceFromTheBeach && <UVP icon={<BeachIcon size="4" />} label="Odległość od morza" value={distanceFromTheBeach} />}
                        <UVP
                            icon={<LikeIcon size="4" />}
                            label="Pozwolenie na wynajem"
                            popover="W Hiszpanii nie wszystkie nieruchomości mogą być wynajmowane w celach komercyjnych. Przed podjęciem decyzji o wynajmie, upewnij się, czy dana nieruchomość spełnia lokalne wymogi prawne dotyczące wynajmu komercyjnego."
                            value={["Nie", "Tak"][Number(offer.commercial_rental)]}
                        />
                    </>
                )}
                {floors && <UVP icon={<FloorsIcon size="4" />} label="Kondygnacje" value={floors} />}
                {offer.buildings && <UVP icon={<CityIcon size="4" />} label="Budynki" value={offer.buildings} />}
                <UVP icon={<BookmarkIcon size="4" />} label="Stand. wykończenia" value={standard} />
            </div>
        </Container>
    );
};

//  Utils
function getRoomsRange(offer: IOfferDetail) {
    if (offer.stats && Array.isArray(offer.stats.rooms) && offer.stats.rooms.length > 1) {
        return `${Math.min(...offer.stats.rooms)} - ${Math.max(...offer.stats.rooms)}`;
    }

    if (offer.stats && Array.isArray(offer.stats.rooms) && offer.stats.rooms.length === 1) {
        return offer.stats.rooms[0];
    }

    return "-";
}

export const distanceFromFormat = (value: number) => {
    const kilometres = value / 1000;
    const metres = value % 1000;

    return kilometres >= 1 ? kilometres.toFixed(Number(!!metres)) + " km" : metres + " m";
};

//  Styles
const uvps = (theme: Theme) => css`
    flex-wrap: wrap;
    width: 100%;
    ${flex("center", "flex-start")};
    ${ph(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16rem, auto));
        grid-template-rows: auto;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        ${flex("center", "space-between")};
        flex-wrap: nowrap;
    }
`;
