import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize, flexAbsoluteCenter, ml, onDesktop, pointer} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons-module";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text-module";

interface IProps {
    icon: React.ReactNode;
    label: string;
    value: React.ReactNode;
    popover?: React.ReactNode;
}

export const UVP = (props: IProps) => {
    return (
        <div css={uvp}>
            {props.icon}
            <div css={content}>
                <Text variant="info_txt_1" align="center">
                    {props.label}
                </Text>

                <div css={flexAbsoluteCenter}>
                    <Text variant="body_copy_1" strong align="center">
                        {props.value}
                    </Text>

                    {props.popover && (
                        <Popover isActive popoverPlace="bottom" body={<>{props.popover}</>} toggleClickHandler toggleOnClickOutside inline type="action">
                            <InfoIcon css={[ml(), pointer]} size="1.8" />
                        </Popover>
                    )}
                </div>
            </div>
        </div>
    );
};

const uvp = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    gap: ${calculateRemSize(3)};
    width: 50%;
    height: 130px;

    ${onDesktop(css`
        width: 130px;
    `)}
`;

const content = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
    white-space: nowrap;
`;
